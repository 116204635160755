import i18n from 'i18next';
import _capitalize from 'lodash/capitalize';
import pluralize from 'pluralize';
import {
  initReactI18next,
  useTranslation,
  withTranslation,
  getI18n,
} from 'react-i18next';

const companyName = gon.companyName;
const entity = gon.entityName;
const entityPlural = pluralize.plural(entity);
const entityCapitalized = _capitalize(entity);
const entityCapitalizedPlural = _capitalize(entityPlural);

const resources = {
  en: {
    translation: {
      companyName,
      entity,
      entityCapitalized,
      entityPlural,
      entityCapitalizedPlural,
      UrlParamsSection: {
        caption:
          'Add the URL parameters that you want to track for each response. They will show up in your $t(entity) export',
      },
      KeywordsSection: {
        caption:
          'Automatically respond to incoming texts with this $t(entityCapitalized) by creating one or more keyword triggers.',
      },
      TextbotPage: {
        subtitle:
          'Allow responders to interact with your $t(entityPlural) via SMS text.',
      },
      EmbedSection: {
        widgetType: {
          basic: {
            description:
              'Works for most cases. If your website uses AJAX page loading such as React, Ember, Angular, MeteorJS, Turbolinks, or Squarespace, you will need to use the Single-Page Apps Code method.',
            title: 'Copy & paste code into the page <BODY>',
            hint: 'Copy and paste this code snippet in the body of each page that you want this $t(entityCapitalized) to appear.',
          },
          spa: {
            description: 'Use this method if your website uses AJAX page loading such as React, Ember, Angular, MeteorJS. This also applies to Squarespace websites.',
            head: {
              title: '1. COPY & PASTE code BEFORE </HEAD> TAG',
              hint: 'Paste this into your global site header. If your website has multiple headers, paste this code in the header of every page that you want a button to be shown.',
            },
            body: {
              title: '2. COPY & PASTE code INTO THE PAGE <BODY>',
              hint: 'Copy and paste this code snippet in the body of each page that you want this Experience to appear.',
            },
          },
        },
        dimensionsHint:
          'We will calculate the optimal height for your embedded $t(entityCapitalized). If you want to override, simply change the height value in the code below.',
        settingsHint:
          'Activate to have embedded $t(entityCapitalized) goals only display progress from each specific embed. This only applies to block and $t(entity) wide goals.',
        loadingSpinnerHint:
          'Activate to show animated spinner while embedded $t(entityCapitalized) is loading on the page.',
        codeHint:
          'This is the code for your $t(entityCapitalized) and will appear on the page that you include this snippet.',
      },
      LightboxSection: {
        dimensionsHint:
          'Standard makes all of your blocks a fixed size and presents your $t(entity) like on a mobile device. Dynamic sizing will adjust the embed frame based on the size of your content.',
        layoutHint:
          'Responsive lightboxes adjust based on the device width. Fixed lightboxes have static width and height with mobile dimensions.',
      },
      NewExperienceDropdown: {
        buttonText: '+ New $t(entityCapitalized)',
      },
      EditEventForm: {
        experienceCompletionLabel: '$t(entityCapitalized) Completion',
        paymentCompletionLabel: 'New Payment',
        paymentFailedLabel: 'Payment Failed',
      },
      EventCard: {
        experienceCompletionLabel: '$t(entityCapitalized) Completion',
        paymentCompletionLabel: 'New Payment',
        paymentFailedLabel: 'Payment Failed',
      },
      EditActionForm: {
        emailLabel: 'Send an email',
        textbotLabel: 'Send $t(entityCapitalized) via SMS',
        cancelReccuringLabel: 'Cancel subscription',
      },
      ActionCard: {
        emailLabel: 'Send an email',
        textbotLabel: 'Send $t(entityCapitalized) via SMS',
        cancelReccuringLabel: 'Cancel subscription',
        TextbotActionForm: {
          targerExperienceLabel: '$t(entityCapitalized) to send',
          targerExperiencePlaceholder: 'Choose an $t(entity)',
          mentions: {
            expTitle: '$t(entityCapitalizedPlural)',
          },
        },
      },
      MentionsInput: {
        OptionsList: {
          expTitle: '$t(entityCapitalized) Title',
          expMeta: '$t(entityCapitalized) Metadata',
          expUrl: '$t(entityCapitalized) URL',
          subtitle: 'This $t(entityCapitalized)',
        },
        expTitle: '$t(entityCapitalizedPlural)',
        mentions: {
          expTitle: '$t(entityCapitalizedPlural)',
        },
      },
      GoalSection: {
        RaiseAmountForm: {
          descriptionText:
            'Choose to display an individual goal that only this block contributes to, or a cumulative, account-wide goal that all $t(entityCapitalizedPlural) contribute to.',
          goalOptions: {
            blockLabel: 'This block',
            experienceLabel: 'This $t(entity)',
            askerLabel: 'This account',
            variantLabel: 'This $t(entity) variant',
          },
          mentions: {
            expTitle: '$t(entityCapitalizedPlural)',
          },
        },
        SubmissionAmountForm: {
          mentions: {
            expTitle: '$t(entityCapitalizedPlural)',
          },
        },
        OtherAmountForm: {
          mentions: {
            expTitle: '$t(entityCapitalizedPlural)',
          },
        },
      },
      MetricSection: {
        blockLabel: 'Amount raised by this block',
        experienceLabel: 'Amount raised by this $t(entity)',
        askerLabel: 'Amount raised by account',
        variantLabel: 'Amount raised by this $t(entity) variant',
        MetricItem: {
          mentions: {
            expTitle: '$t(entityCapitalizedPlural)',
          },
        },
        MetricItemOther: {
          mentions: {
            expTitle: '$t(entityCapitalizedPlural)',
          },
        },
      },
      TitleSection: {
        mentions: {
          expTitle: '$t(entityCapitalizedPlural)',
        },
      },
      BackgroundLayout: {
        hint:
          'Use media uploaded from other $t(entity) responses as unique backgrounds of this $t(entity). If no media exists, your chosen background will be used as a fallback.',
        mentions: {
          expTitle: '$t(entityCapitalizedPlural)',
        },
      },
      TransitionButton: {
        mentions: {
          expTitle: '$t(entityCapitalizedPlural)',
        },
      },
      EditableExperienceTitle: {
        placeholderText: 'Give your $t(entityCapitalized) a name...',
        mentions: {
          expTitle: '$t(entityCapitalizedPlural)',
        },
      },
      HideBrandingSection: {
        switchTitle: 'Hide $t(companyName) branding',
      },
      MultipleChoiceSection: {
        mentions: {
          expTitle: '$t(entityCapitalizedPlural)',
        },
      },
      ExperienceList: {
        FilterDropdown: {
          expTitle: '$t(entityCapitalizedPlural)',
        },
      },
      ExperiencesList: {
        noForms: 'No forms found',
      },
      ExperienceListItem: {
        raisedAmount: '{{amount}} raised',
        view: 'View $t(entity)',
        viewVariant: 'View variant',
        edit: 'Edit $t(entity)',
        duplicate: 'Duplicate',
        responses: 'Responses',
        archive: 'Archive',
        hide: 'Hide',
        show: 'Show',
        variantsLink: '{{count}} variants',
      },
      ArchiveFormModal: {
        title: 'Confirm Archive',
        content:
          'Are you sure you want to archive this formli?<br /><br />When archived, this formli will stop accepting responses and your response data will be inaccesible.',
      },
      HideFormliModal: {
        title: 'Hide $t(entityCapitalized)',
        variantTitle: 'Hide Variant',
        content: 'Responses will no longer be collected when set to ‘Hide’.',
        hideVariants: 'Hide all ({{amount}}) variants',
      },
      ShowFormliModal: {
        title: 'Show $t(entityCapitalized)',
        variantTitle: 'Show Variant',
        content: 'Resume collecting responses when set to ‘Show’.',
        showVariants: 'Show all ({{amount}}) variants',
      },
      ExportsModal: {
        export: 'Export',
        exportToCSV: 'export responses to csv',
        exportToGoogleSheets: 'export responses to google sheets',
        explanation:
          'Copy this code and paste it into the top cell of your Google Sheet. New data will automatically update in this sheet every hour.',
        download: 'Download CSV',
        copy: 'Copy',
      },
      ResetViewModal: {
        title: 'Reset dashboard?',
        content: 'This will reset your dashboard to the default state.',
      },
      PaymentSection: {
        description: 'Use your existing paypal account to collect payments.',
        mentions: {
          expTitle: '$t(entityCapitalizedPlural)',
        },
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
export { useTranslation, withTranslation, getI18n };
